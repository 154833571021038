@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Barlow:400,700');

@import 'variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'utils.scss';

@import './components/body.scss';
@import './components/footer.scss';
@import './components/nav.scss';
@import './components/date.scss';
@import './components/input.scss';
@import './components/headings.scss';
@import './components/buttons.scss';
@import './components/divider.scss';
@import './components/slider.scss';
@import './components/logo.scss';
@import './components/header.scss';
@import './components/hero.scss';
@import './components/col.scss';